import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { Report, KV } from "../models/report";
import { Timeseries } from "../models/timeseries";
import { ConfigService } from "./config.service";
import { retryWhen, delay, tap } from "rxjs/operators";
import moment from "moment";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  classifications = ["source", "inline", "eval", "form", "mixed-content", "injected", "bot", "extension", "unactionable", "malformed"];
  browsers = ["chrome", "firefox", "safari", "edge", "ie", "samsung", "uc", "other"];
  buckets = ["day", "hour", "minute", "second", "month"];

  constructor(private http: HttpClient, private config: ConfigService) {}

  getReports(projectID: string, group: boolean): Observable<Report[]> {
    return this.http.get<Report[]>(`${this.config.getOrigin()}/api/projects/${projectID}/reports?group=${group}`);
  }

  getReportStats(projectID: string, hash: string): Observable<Report> {
    return this.http.get<Report>(`${this.config.getOrigin()}/api/projects/${projectID}/reports/${hash}/stats`);
  }

  getReportsFiltered(
    projectID: string,
    policyID: string,
    hash: string,
    isGroup: boolean,
    bucket: string,
    isFilterOldBrowsers: boolean,
    directives: string,
    classifications: string,
    browsers: string,
    queryTags: KV[],
    limit: number,
    page: number,
    start?,
    end?: string
  ) {
    if (!end) {
      end = "";
    }
    if (!start) {
      start = "";
    }

    if (bucket == "month") {
      bucket = "day";
    }

    let queryParamTagString = this.KVToObject(queryTags);

    return this.http.get<Report[]>(
      `${this.config.getOrigin()}/api/projects/${projectID}/reports?policy=${policyID}&bucket=${bucket}&hash=${hash}&group=${isGroup}&directives=${directives}&classifications=${classifications}&limit=${limit}&page=${page}&browsers=${browsers}&queryStringTags=${queryParamTagString}&isFilterOldBrowsers=${isFilterOldBrowsers}&start=${start}&end=${end}`
    );
  }

  countReports(projectID: string, policyID: string, hash: string): Observable<Report> {
    return this.http.get<Report>(`${this.config.getOrigin()}/api/projects/${projectID}/reports/count?policy=${policyID}&hash=${hash}`);
  }

  getReportsForPolicy(projectID: string, policyID: string, group: boolean): Observable<Report[]> {
    return this.http.get<Report[]>(`${this.config.getOrigin()}/api/projects/${projectID}/reports?policy=${policyID}&group=${group}`);
  }

  getReportsForHash(projectID: string, policyID: string, hash: string): Observable<Report[]> {
    return this.http.get<Report[]>(`${this.config.getOrigin()}/api/projects/${projectID}/reports?policy=${policyID}&hash=${hash}`);
  }

  getReportsWS(projectID: string): Observable<Report> {
    if (window.location.protocol == "http:") {
      return webSocket<Report>(`ws://${this.config.getWSOrigin()}/api/projects/${projectID}/reports/ws`).pipe(
        retryWhen((errors) =>
          errors.pipe(
            tap((err) => {
              console.error("Error reconnecting", err);
            }),
            delay(1000)
          )
        )
      );
    } else {
      return webSocket<Report>(`wss://${this.config.getWSOrigin()}/api/projects/${projectID}/reports/ws`).pipe(
        retryWhen((errors) =>
          errors.pipe(
            tap((err) => {
              console.error("Error reconnecting", err);
            }),
            delay(1000)
          )
        )
      );
    }
  }

  //  getReportsFiltered(projectID: string, policyID: string, hash: string, isGroup: boolean, bucket: string, directive: string, limit: number, page: number) {
  getReportTimeseries(
    projectID: string,
    policyID: string,
    hash: string,
    isGroup: boolean,
    bucket: string,
    directives: string,
    classifications: string,
    browsers: string,
    queryTags: KV[],
    isFilterOldBrowsers: boolean,
    start,
    end: string
  ): Observable<Timeseries> {
    if (bucket == "month") {
      bucket = "day";
    }

    let queryParamTagString = this.KVToObject(queryTags);

    return this.http.get<Timeseries>(
      `${this.config.getOrigin()}/api/projects/${projectID}/reports/timeseries?policy=${policyID}&bucket=${bucket}&hash=${hash}&group=${isGroup}&directives=${directives}&classifications=${classifications}&browsers=${browsers}&queryStringTags=${queryParamTagString}&isFilterOldBrowsers=${isFilterOldBrowsers}&start=${start}&end=${end}`
    );
  }

  insertSampleReports(projectID: string): Observable<any> {
    return this.http.post(`${this.config.getOrigin()}/api/projects/${projectID}/reports/sample`, "", { responseType: "text" });
  }

  deleteReports(projectID: string): Observable<any> {
    return this.http.delete(`${this.config.getOrigin()}/api/projects/${projectID}/reports`, { responseType: "text" });
  }

  flattenKeyMap(inMap: object): string {
    let outStr = "";
    for (let key of Object.keys(inMap)) {
      if (inMap[key]) {
        outStr += key + ",";
      }
    }

    if (outStr.length > 0) {
      outStr = outStr.substr(0, outStr.length - 1);
    }
    return outStr;
  }

  fillKeyMap(inStr: string): object {
    if (!inStr || inStr.length == 0) {
      return {};
    }

    let out = {};
    for (let k of inStr.split(",")) {
      out[k] = true;
    }

    return out;
  }

  downgradeBucket(bucket: string): string {
    if (bucket == "day" || bucket == "month") return "hour";
    if (bucket == "hour") return "minute";
    if (bucket == "minute") return "second";
    if (bucket == "second") return "second";
  }

  isValidBucket(bucket: string): boolean {
    for (let b of this.buckets) {
      if (b == bucket) {
        return true;
      }
    }
    return false;
  }

  realBucket(bucket: string): string {
    if (bucket == "month") {
      return "day";
    }

    return bucket;
  }

  bucketCount(startStr, endStr, bucket: string): number {
    if (!this.isValidBucket(bucket)) {
      console.error("invalid bucket", bucket);
    }

    let count = 0;

    let end = moment(endStr);
    let curr = moment(startStr);
    while (curr.isBefore(end)) {
      // @ts-ignore
      curr = curr.add(1, bucket);
      count++;

      if (count > 1000) {
        return count;
      }
    }
    return count;
  }

  KVToObject(arr: KV[]): string {
    if (!arr || typeof arr == undefined) {
      return "";
    }

    let queryParamTagString = "";
    if (arr.length > 0) {
      let queryParamTags = {};
      for (let k of arr) {
        queryParamTags[k.key] = k.value;
      }
      return JSON.stringify(queryParamTags);
    }
    return "";
  }
}
