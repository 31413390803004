import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StatComponent } from "../components/stat/stat.component";
import { TimeAgoPipe } from "../pipes/time-ago.pipe";
import { HostPipe } from "../pipes/host.pipe";
import { ReportQuotaBarComponent } from "../components/report-quota-bar/report-quota-bar.component";
import { AlertErrorComponent } from "../components/alert-error/alert-error.component";
import { PolicyViewComponent } from "../components/policy-view/policy-view.component";
import { FormsModule } from "@angular/forms";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { SortByPipe } from "../pipes/sort-by.pipe";
import { ReportGroupSortPipe } from "../pipes/report-group-sort.pipe";
import { SearchPipe } from "../pipes/search.pipe";
import { PricingPanelsComponent } from "../components/pricing-panels/pricing-panels.component";
import { RouterModule } from "@angular/router";
import { HelpCircle, Check, X, ArrowRight, Settings, Edit2, Trash2 } from "angular-feather/icons";
import { FeatherModule } from "angular-feather";
import { HoverClassDirective } from "../directives/hover-class.directive";
import { ProjectOrgSearchPipe } from "../pipes/project-org-search.pipe";
import { OrgProjectSelectorComponent } from "../components/org-project-selector/org-project-selector.component";
import { ReportChartComponent } from "../components/report-chart/report-chart.component";
import { NgChartsModule } from "ng2-charts";
import "chartjs-adapter-date-fns";
import { AutosizeModule } from "ngx-autosize";

@NgModule({ declarations: [
        StatComponent,
        TimeAgoPipe,
        ProjectOrgSearchPipe,
        OrgProjectSelectorComponent,
        HostPipe,
        ReportQuotaBarComponent,
        AlertErrorComponent,
        PolicyViewComponent,
        SortByPipe,
        ReportGroupSortPipe,
        SearchPipe,
        PricingPanelsComponent,
        HoverClassDirective,
        ReportChartComponent,
    ],
    exports: [
        StatComponent,
        TimeAgoPipe,
        ProjectOrgSearchPipe,
        OrgProjectSelectorComponent,
        HostPipe,
        ReportQuotaBarComponent,
        AlertErrorComponent,
        PolicyViewComponent,
        SortByPipe,
        ReportGroupSortPipe,
        SearchPipe,
        PricingPanelsComponent,
        HoverClassDirective,
        ReportChartComponent,
    ], imports: [CommonModule,
        FormsModule,
        NgChartsModule,
        AutosizeModule,
        FeatherModule.pick({ HelpCircle, Check, X, ArrowRight, Settings, Edit2, Trash2 }),
        RouterModule], providers: [
        TimeAgoPipe,
        HostPipe,
        SortByPipe,
        ReportGroupSortPipe,
        SearchPipe,
        HoverClassDirective,
        ProjectOrgSearchPipe,
        OrgProjectSelectorComponent,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class SharedModule {}
