import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";
import { Observable } from "rxjs";

export interface APIKey {
  id: string;
  ts: string;
  dla: string;
  name: string;
  createdBy: string;
  projectID: string;
  organizationID: string;
  role: string;
  comment: string;
  token: string;
}

@Injectable({
  providedIn: "root",
})
export class ApikeyService {
  constructor(private http: HttpClient, private config: ConfigService) {}

  getAPIKeysForProject(projectID: string): Observable<APIKey[]> {
    return this.http.get<APIKey[]>(`${this.config.getOrigin()}/api/projects/${projectID}/apikeys`);
  }

  getAPIKeysForOrganization(orgID: string): Observable<APIKey[]> {
    return this.http.get<APIKey[]>(`${this.config.getOrigin()}/api/organizations/${orgID}/apikeys`);
  }

  newAPIKey(key: APIKey): Observable<APIKey> {
    return this.http.post<APIKey>(`${this.config.getOrigin()}/api/apikeys`, key);
  }

  deleteAPIKey(keyID: string): Observable<any> {
    return this.http.delete(`${this.config.getOrigin()}/api/apikeys/${keyID}`);
  }
}
