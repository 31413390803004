import { Component, OnInit } from "@angular/core";
import { ErrorService } from "src/app/services/error.service";

declare var $: any;

@Component({
  selector: "app-alert-error",
  templateUrl: "./alert-error.component.html",
  styleUrls: ["./alert-error.component.css"],
})
export class AlertErrorComponent implements OnInit {
  error: string;

  constructor(private errorService: ErrorService) {
    this.error = "";
  }

  ngOnInit(): void {
    this.errorService.lastError$.subscribe((error) => {
      if (error != "") {
        console.log("alert-error recieved error", error);
      }
      this.error = error;
    });
  }
}
