<div class="btn-group btn-group-toggle float-right btn-sm report-chart-button-group" data-toggle="buttons" *ngIf="!hideBucketButtons">
  <label class="btn btn-secondary btn-sm report-chart-button" [class.active]="timeRange == 'hour'" (click)="timeRange = 'hour'; loadData()">
    <input type="radio" name="options" value="small" checked />Hour
  </label>
  <label class="btn btn-secondary btn-sm report-chart-button" [class.active]="timeRange == 'day'" (click)="timeRange = 'day'; loadData()">
    <input type="radio" name="options" /> Day
  </label>
  <label class="btn btn-secondary btn-sm report-chart-button" [class.active]="timeRange == 'day'" (click)="timeRange = 'month'; loadData()">
    <input type="radio" name="options" /> Month
  </label>
</div>

<div *ngIf="chartData.length" [style.height]="height">
  <canvas
    baseChart
    [legend]="!hideLegend"
    [datasets]="chartData"
    [labels]="chartLabels"
    [options]="chartOptions"
    [type]="'line'"
    (click)="handleClick($event)"
    #chart
  ></canvas>
</div>

<div *ngIf="!chartData || !chartData.length" class="text-center">
  <p>No data in specified chart range.</p>
</div>
