import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, ViewChild } from "@angular/core";
import { ReportService } from "src/app/services/report.service";
import { KV, ReportGroup } from "src/app/models/report";
import { BaseChartDirective } from "ng2-charts";
import moment from "moment";

@Component({
  selector: "app-report-chart",
  templateUrl: "./report-chart.component.html",
  styleUrls: ["./report-chart.component.css"],
})
export class ReportChartComponent implements OnInit {
  @Input() projectID: string;
  @Input() policyID: string;
  @Input() hash: string;
  @Input() timeRange: string;
  @Input() isGroup: boolean;
  @Input() hideBucketButtons: boolean;
  @Input() hideLegend: boolean;

  @Input() directives: string;
  @Input() classifications: string;
  @Input() browsers: string;

  @Input() queryTags: KV[];

  @Input() isFilterOldBrowsers: boolean;
  @Input() height: string;

  @Input() start: string;
  @Input() end: string;

  @Input() reportGroups: ReportGroup[];

  @Input() isLoadGroups: boolean;

  chartData: any;
  chartLabels: string[];
  chartOptions: any;

  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;

  constructor(private reportService: ReportService) {
    if (!this.projectID) {
      this.projectID = "";
    }
    if (!this.policyID) {
      this.policyID = "";
    }
    if (!this.hash) {
      this.hash = "";
    }
    if (!this.directives) {
      this.directives = "";
    }
    if (!this.classifications) {
      this.classifications = "";
    }
    if (!this.browsers) {
      this.browsers = "";
    }
    if (!this.timeRange) {
      this.timeRange = "day";
    }
    if (!this.height) {
      this.height = "1000px";
    }
    if (!this.hideLegend) {
      this.hideLegend = false;
    }
    if (!this.isFilterOldBrowsers) {
      this.isFilterOldBrowsers = false;
    }
    if (!this.start) {
      this.start = "";
    }
    if (!this.end) {
      this.end = "";
    }

    //chartOptions

    this.chartData = [];
    this.chartLabels = [];
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      bezierCurve: true,
      scales: {
        x: {
          type: "time",
        },

        y: {
          min: 0,
        },
      },
    };
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.loadData();

    if (!this.start && !this.end && this.chart) {
    }
  }

  generateLabels(timeRange: string) {
    let bucket: "minute" | "hour" | "day" = "minute";
    let start = moment();
    let end = moment();
    switch (timeRange) {
      case "hour":
        bucket = "minute";
        start = moment().utc().subtract(1, "hours").startOf("minute");
        end = moment().utc().startOf("minute");
        break;
      case "day":
        bucket = "hour";
        start = moment().utc().subtract(1, "days").startOf("hour");
        end = moment().utc().startOf("hour");
        break;
      case "week":
        bucket = "day";
        start = moment().utc().subtract(7, "days").startOf("day");
        end = moment().utc().startOf("day");
        break;
      case "month":
        bucket = "day";
        start = moment().utc().subtract(1, "months").startOf("day");
        end = moment().utc().startOf("day");
        break;
      case "all":
        bucket = "day";
        start = moment().utc().subtract(3, "months").startOf("day");
        end = moment().utc().startOf("day");
        break;
      default:
        console.log("invalid time range...", timeRange);
    }

    // console.log("generateLabels", start.format("YYYY-MM-DD HH:mm:ss"), end.format("YYYY-MM-DD HH:mm:ss"), bucket, timeRange);

    let labels = [];
    let current = start;
    while (current <= end) {
      labels.push(current.unix());
      current = current.add(1, bucket);
    }
    return labels;
  }

  grabLabels() {
    let out = [];
    for (let reportGroup of this.reportGroups) {
      for (let bucket of reportGroup.buckets) {
        out.push(moment(bucket.start).unix());
      }
    }
    return out.sort((a, b) => a - b).filter((v, i, a) => a.indexOf(v) === i);
  }

  loadData() {
    if (!this.reportGroups || this.reportGroups.length == 0) {
      this.chartData = [];

      return;
    }
    this.chartData = [];
    let unixLabels = this.generateLabels(this.timeRange);
    this.chartLabels = unixLabels.map((u) => moment.unix(u).format("YYYY-MM-DD HH:mm:ss"));

    // console.log("Labels", unixLabels, this.chartLabels);

    let directives = this.reportGroups.map((r) => r.directive).filter((v, i, a) => a.indexOf(v) === i);
    // console.log("directives", directives);

    for (let directive of directives) {
      this.chartData.push({ label: directive, data: new Array(this.chartLabels.length).fill(0), lineTension: 0.4 });
    }

    for (let reportGroup of this.reportGroups) {
      for (let bucket of reportGroup.buckets) {
        let index = unixLabels.indexOf(moment(bucket.start).unix());
        if (index == -1) {
          // console.log("missing index", index, moment(bucket.start).unix(), moment(bucket.start), bucket.start);
        }
        if (index > -1) {
          this.chartData[directives.indexOf(reportGroup.directive)].data[index] += bucket.count;
        }
      }
    }

    // console.log("chartData", this.chartData);
  }

  handleClick($event) {
    console.log($event);
  }
}
